import { ApolloClient, InMemoryCache, DefaultOptions, FetchPolicy } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

const restLink = new RestLink({
    uri: BASE_URL,
});

const authLink = setContext((_, { headers }) => {
    const tokenStorage = sessionStorage.getItem('token');
    let token = '';
    if (tokenStorage) {
        try {
            const sessionToken = JSON.parse(tokenStorage);
            token = sessionToken.token;
        } catch (e) {
            console.error('Error parsing token from sessionStorage', e);
        }
    }
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
        },
    };
});

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-first' as FetchPolicy,
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'cache-first' as FetchPolicy,
        errorPolicy: 'all',
    },
};

const client = new ApolloClient({
    link: authLink.concat(restLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});

export default client;
